.snippet {
    display: flex;
    align-items: center;
    width: 100%;
    cursor: pointer;
    padding-left: 1rem;
}

.snippet:hover {
    background-color: white;
    border-radius: 10px;
}

.snippet .key {
    width: 69px;
    background-color: #335eea;
    border-radius: 3px;
    font-family: Open Sans;
    font-size: 12px;
    color: #ffffff;
    text-align: center;
    padding: 4px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-right: 10px;
}

.snippet .value {
    font-family: Open Sans;
    font-size: 14px;
    color: #000000;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 94%;
}

.snippet-container {
    width: 85%;
    height: 21px;
    overflow: hidden;
}

.snippet-container p  {
    margin-top: 0;
}

.delete-button:hover {
    border-radius: 3px;
    background-color: rgba(169, 169, 169, 0.4);
}

.edit-button:hover {
    border-radius: 3px;
    background-color: rgba(169, 169, 169, 0.4);
}

.delete-button {
    align-items: center;
    display: flex;
    border: none!important;
    cursor: pointer;
    background-color: transparent;
    padding: 10px;
}

.edit-button {
    align-items: center;
    display: flex;
    border: none!important;
    background-color: transparent;
    cursor: pointer;
    padding: 10px;
}
