.navbar {
    height: 100vh;
    width: 4%;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.navbar .logo {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #335EEA;
    height: 60px;
}

.navbar__icon {
    margin-top: 1rem;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    cursor: pointer;
}

.navbar__icon:hover {
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
}


.navbar__icon_selected {
    margin-top: 1rem;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    cursor: pointer;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
}