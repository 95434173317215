.planchoice {
    width: 266px;
    background: #ffffff;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 3px;
}

.planchoice__active {
    border: 1px solid #0F70EB;
}

.planchoice__discount {
    background: #335eea;
    border-radius: 3px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #ffffff;
    align-items: center;
    display: flex;
    align-items: center;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-right: 8px;
    padding-left: 8px;
}

.planchoice p {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    color: #000000;
    margin: 1rem;
}

.planchoice span {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    color: #000000;
    opacity: 0.2;
}

