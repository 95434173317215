.radio-button {
    width: 16px;
    height: 16px;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
}

.radio-button__active-part {
    width: 8px;
    height: 8px;
    border-radius: 8px;
    background-color: #335EEA;
}

.radio-button__notactive-part {
    width: 8px;
    height: 8px;
    border-radius: 8px;
    background-color: white;
}