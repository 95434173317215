body {
    background-color: #F3F5F7;
}

button {
    border: none;
}

.home {
    width: 96%;
    display: flex;
    background-color: #F3F5F7;
}

.shadow-right {
    box-shadow: 12px 22px 30px 3px rgba(0, 0, 0, 0.25);
}