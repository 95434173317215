.welcome {
    padding-top: 3rem;
    position: relative;
}

.welcome h1 {
    text-align: center;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 21px;
    color: #000000;
    margin-bottom: 3rem;
}

.welcome p {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #000000;
}

.welcome__gif {
    margin-top: 3rem;
    text-align: center;
}

.blue_arrow {
    position: absolute;
    bottom: 10rem;
    right: 3px;
    transform: rotate(-12deg)
}
