.all-snip {
    padding: 2rem;
    /* padding-top: 2rem;
    padding-left: 2rem; */
}

.all-snip__title-container {
    display: flex;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.all-snip__top-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.all-snip__title-container h1 {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #000000;
    margin: 0;
    margin-right: 0.5rem;
}

.all-snip__title-container span {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #B1B4BA;
}

.create-button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    height: 2rem;
    cursor: pointer;
}
