.chrome-btn {
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #335eea;
    border-radius: 3px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    color: #ffffff;
    padding-right: 1rem;
    padding-left: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    margin-top: 3rem;
    cursor: pointer;
}

a {
    text-decoration: none!important;
}

.install-skip {
    font-style: normal;
    font-weight: normal;
    font-size: 12px!important;
    color: #7b7b7b;
}

.install-skip span {
    cursor: pointer;
    color: #335EEA;
}
