.create-snip {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    position: relative;
}
/* not paying */
.not-paying {
    position: absolute;
    background-color: rgb(255, 255, 255, 0.8);
    z-index: 100;
    width: 100%;
    height: 100%;
    overflow: hidden;
    /* opacity: 0.8; */
}

.not-paying__container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    padding-right: 2rem;
    padding-left: 2rem;
    position: relative;
}

.not-paying__container img {
    bottom: 0px;
    right: 64px;
    position: absolute;
}

.not-paying__elems {
    display: flex;
    padding-right: 15%;
    padding-left: 15%;
    flex-direction: column;
    align-items: center;
}

.not-paying__container h1 {
    font-family: Open Sans;
    font-weight: 600;
    font-size: 21px;
    line-height: 29px;
    text-align: center;
    color: #000000;
}

.not-paying__container span {
    font-family: Open Sans;
    font-size: 12px;
    text-align: center;
}
/* not paying */
.create-snip h1 {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 21px;
    line-height: 29px;
    color: #000000;
}

.create-snip p {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #000000;
}

#keysnippet {
    background: #ffffff;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    padding-right: 20px;
    padding-left: 20px;
    padding-top: 10px;
    border: none;
    width: 200px;
    padding-bottom: 10px;
}

#keysnippet::placeholder {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 19px;
    color: #b1b4ba;
}

#valuesnippet {
    height: 210px;
    margin-bottom: 1rem;
    background: #ffffff;
    box-shadow: 2px 2px 12px -2px rgba(0, 0, 0, 0.25);
    border-radius: 2px;
    border: none;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #000000;
    padding: 1rem;
}

#valuesnippet::placeholder {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    padding: 1rem;
    color: #b1b4ba;
}

.button-create-snippet {
    width: 168px;
    margin-top: 1.5rem;
    background: #ffffff;
    border: 1px solid #bec4cd;
    font-style: normal;
    border-radius: 3px;
    font-family: Open Sans;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    color: #000000;
    padding-right: 20px;
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer;
}

.button-create-snippet:disabled {
    width: 168px;
    margin-top: 1.5rem;
    background: #ffffff;
    border: 1px solid #bec4cd;
    font-style: normal;
    border-radius: 3px;
    font-family: Open Sans;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    color: #bec4cd;
    padding-right: 20px;
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: not-allowed;
}

textarea {
    resize: none;
}

.reminder-error-message {
    font-family: Open Sans;
    color: rgb(228, 99, 99);
    font-size: 12px;
}
