.settings {
    display: flex;
    flex-direction: column;
    padding-left: 3rem;
}

.settings p {
    margin-bottom: 0px;
}

.sign-details {
    background: white;
    border-radius: 3px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #000000;
    border: none;
    width: 100%;
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin-top: 1rem;
}

.sign-details::placeholder {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #000000;
    opacity: 0.5;
}

.sign-details[type="text"] {
    padding-left: 1rem;
}

.sign-details[type="password"] {
    padding-left: 1rem;
}

.reset-password-profil {
    margin-top: 2rem;
    margin-bottom: 2rem;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #000000;
}

.reset-password-profil span {
    font-family: Open Sans;
    cursor: pointer;
    font-size: 14px;
    text-decoration: underline;
    color: #335EEA;
}
