@media screen and (max-width: 1000px) {
    .sign__container-image {
        display: none!important;
    }

    .sign__container-input {
        width: 100%!important;
    }

    .terms-service {
        text-align: center;
    }

    .container-name {
        width: 77%!important;
    }
}


.container-name {
    display: flex;
    justify-content: space-between;
    width: 50%
}

.sign {
    display: flex;
    width: 100%;
}

.sign--title {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    color: #000000;
}

.sign p {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #7b7b7b;
}

.sign__container-image {
    width: 50%;
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
}

.sign__container-input {
    width: 50%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: white;
    height: 100vh;
}

.sign-button {
    background: rgb(51, 94, 234);
    border-radius: 3px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    color: #ffffff;
    padding: 1rem;
    border: none;
    margin-top: 2rem;
    width: 50%;
    cursor: pointer;
}

.sign-button:hover {
    background: rgba(51, 94, 234, 0.9);
}

.sign-input {
    background: #f3f5f7;
    border-radius: 3px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #000000;
    border: none;
    width: 48%;
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin-top: 1rem;
}

.sign-input::placeholder {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #000000;
    opacity: 0.5;
}

.sign-input[type="text"] {
    padding-left: 1rem;
}

.sign-input[type="password"] {
    padding-left: 1rem;
}

.google-btn {
    background: #ffffff;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    width: 50%;
    padding-top: 1rem;
    padding-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #7b7b7b;
    cursor: pointer;
}

.google-btn:hover {
    background: #ffffff;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
    /* box-shadow: none; */
}

.google-btn img {
    margin-right: 1rem;
}

.custom-line {
    width: 50%;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    text-align: center;
    color: #000000;
    opacity: 0.5;
    text-align: center;
    border: 1px solid #ededed;
    line-height: 0.1px;
    margin: 10px 0 20px;
}

.custom-line span {
    background: #fff;
    padding: 0 10px;
}
