.pricing {
    padding-top: 4rem;
    /* padding-right: 10%;
    padding-left: 10%; */
    display: flex;
    width: 96%;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}

.pricing h1 {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 16px;
    text-align: center;
    color: #000000;
}

.princing-container {
    padding: 3rem;
    text-align: center;
    background: #ffffff;
    box-shadow: 8px 8px 24px -2px rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 50%;
}

.princing-container .text-pricing {
    margin-bottom: 3rem;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #000000;
}

.pricing .contact-us {
    margin-top: 3rem;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    text-align: center;
    color: #000000;
}

.blue_button {
    background: #0f70eb;
    border-radius: 3px;
    padding: 1rem;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    color: #ffffff;
    margin-top: 2rem;
    cursor: pointer;
}

.blue_button:hover {
    opacity: 0.7;
}

